<template>
  <v-dialog
    v-model="dialog"
    max-width="490"
    @click:outside="$emit('Sil', false)"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>

      <v-card-text style="font-size:1.2rem">
        {{ $t('SIL.SILMEK_ISTEDINIZE_EMINMISINIZ') }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="green darken-1"
          outlined
          rounded
          @click="$emit('Sil', false)"
        >
          {{ $t('HAYIR') }}
        </v-btn>

        <v-btn
          color="#F51616"
          outlined
          rounded
          @click="$emit('Sil', true)"
        >
          {{ $t('EVET') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'SilmeOnayi',
  props: ['sil', 'title'],
  computed: {
    dialog() {
      return this.sil
    },
  },
}
</script>
